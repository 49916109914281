import React, { useState, useEffect, useCallback, useMemo } from "react";
import moment from "moment";
import { connect } from "react-redux";
import "./User.css";
import "react-vis/dist/style.css";
import PageHeader from "../Layout/PageHeader";
import UserButtons from "./UserButtons";
import UserFilters from "./UserFilters";
import { Row, Col, Collapse, Pagination, Tabs, Select } from "antd";
import axios from "axios";
import { CaretRightOutlined } from "@ant-design/icons";
import DangerUserList from "./DangerUserList";
import UserList from "./UserList";

const User = ({ token }) => {
  const [user, setUser] = useState([]);
  const [filters, setFilters] = useState({});
  const [pagination, setPagination] = useState({ currentPage: 1, limit: 10 });
  const [selectedRows, setSelectedRows] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [accountManager, setAccountManager] = useState([]);

  const params = useMemo(
    () => ({
      ...(filters.startDate && {
        startDate: filters.startDate?.startOf("day")?.toISOString(),
      }),
      ...(filters.endDate && {
        endDate: filters.endDate?.endOf("day")?.toISOString(),
      }),
      ...(filters.startLastActiveDate && {
        startLastActiveDate: filters.startLastActiveDate
          ?.startOf("day")
          ?.toISOString(),
      }),
      ...(filters.endLastActiveDate && {
        endLastActiveDate: filters.endLastActiveDate
          ?.endOf("day")
          ?.toISOString(),
      }),
      ...(filters.startLastOrderDate && {
        startLastOrderDate: filters.startLastOrderDate
          ?.startOf("day")
          ?.toISOString(),
      }),
      ...(filters.endLastOrderDate && {
        endLastOrderDate: filters.endLastOrderDate?.endOf("day")?.toISOString(),
      }),
      ...(filters.topupStartDate && {
        topupStartDate: filters.topupStartDate?.startOf("day")?.toISOString(),
      }),
      ...(filters.topupEndDate && {
        topupEndDate: filters.topupEndDate?.endOf("day")?.toISOString(),
      }),
      ...(filters.search && {
        searchField: filters.searchField,
        search: filters.search,
      }),
      limit: pagination.limit,
      page: pagination.currentPage,
      ...(filters.status && { status: filters.status }),
      ...(filters.badge && { badge: filters.badge }),
      ...(filters.topupPackage && { topupPackage: filters.topupPackage }),
      ...(filters.tagging && { tagging: filters.tagging }),
    }),
    [filters, pagination]
  );

  const fetchUser = useCallback(async () => {
    setIsLoading(true);
    try {
      const res = await axios.get("/api/users/all-user", { params });
      setUser(res.data.data);
      setPagination(res.data.pagination);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching users:", error);
    } finally {
      setIsLoading(false);
    }
  }, [pagination.currentPage, pagination.limit, filters]);

  const fetchManager = useCallback(async () => {
    try {
      const res = await axios.get("/api/account-manager?isAdmin=true");
      setAccountManager(res.data.data);
    } catch (error) {
      console.error("Error fetching account managers:", error);
    }
  }, []);

  useEffect(() => {
    fetchUser();
    fetchManager();
  }, [pagination.currentPage, pagination.limit, fetchUser, fetchManager]);

  return (
    <div>
      <PageHeader
        title="User"
        childRoutes={[{ path: "/user", breadcrumbName: "User" }]}
      />
      <div className="table mt-4">
        <Tabs defaultActiveKey="1" animated={true}>
          <Tabs.TabPane tab="All User" key="all-user">
            <Collapse
              expandIcon={({ isActive }) => (
                <CaretRightOutlined rotate={isActive ? 90 : 0} />
              )}
            >
              <Collapse.Panel header="Filters" key="filters">
                <UserFilters filters={filters} onFiltersChange={setFilters} />
              </Collapse.Panel>
            </Collapse>
            <Row className="mt-2 mb-2" justify="space-between">
              <UserButtons
                selectedRowKeys={selectedRows}
                users={user}
                filters={{
                  ...(filters.startDate && {
                    startDate: filters.startDate?.startOf("day")?.toISOString(),
                  }),
                  ...(filters.endDate && {
                    endDate: filters.endDate?.endOf("day")?.toISOString(),
                  }),
                  ...(filters.startLastActiveDate && {
                    startLastActiveDate: filters.startLastActiveDate
                      ?.startOf("day")
                      ?.toISOString(),
                  }),
                  ...(filters.endLastActiveDate && {
                    endLastActiveDate: filters.endLastActiveDate
                      ?.endOf("day")
                      ?.toISOString(),
                  }),
                  ...(filters.startLastOrderDate && {
                    startLastOrderDate: filters.startLastOrderDate
                      ?.startOf("day")
                      ?.toISOString(),
                  }),
                  ...(filters.endLastOrderDate && {
                    endLastOrderDate: filters.endLastOrderDate
                      ?.endOf("day")
                      ?.toISOString(),
                  }),
                  ...(filters.topupStartDate && {
                    topupStartDate: filters.topupStartDate
                      ?.startOf("day")
                      ?.toISOString(),
                  }),
                  ...(filters.topupEndDate && {
                    topupEndDate: filters.topupEndDate
                      ?.endOf("day")
                      ?.toISOString(),
                  }),
                  ...(filters.search && {
                    searchField: filters.searchField,
                    search: filters.search,
                  }),
                  limit: pagination.limit,
                  page: pagination.currentPage,
                  ...(filters.status && { status: filters.status }),
                  ...(filters.badge && { badge: filters.badge }),
                  ...(filters.topupPackage && {
                    topupPackage: filters.topupPackage,
                  }),
                  ...(filters.tagging && { tagging: filters.tagging }),
                }}
                pagination={{ pagination }}
                onChange={fetchUser}
              />
              <Col>
                <Pagination
                  total={pagination?.totalSize}
                  pageSize={pagination?.limit}
                  onChange={(currentPage, limit) =>
                    setPagination({ ...pagination, limit, currentPage })
                  }
                  current={pagination.currentPage}
                />
              </Col>
            </Row>
            <UserList
              users={user}
              selectedRowKeys={selectedRows}
              onSelectRows={setSelectedRows}
              loading={isLoading}
              accountManager={accountManager}
            />
            <Row justify="end">
              <Col>
                <Pagination
                  total={pagination?.totalSize}
                  pageSize={pagination?.limit}
                  onChange={(currentPage, limit) =>
                    setPagination({ ...pagination, limit, currentPage })
                  }
                  current={pagination.currentPage}
                />
              </Col>
            </Row>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Danger User" key="dangerUser">
            <DangerUserList />
          </Tabs.TabPane>
        </Tabs>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  token: state.auth.token,
});

export default connect(mapStateToProps)(User);
