import React, { useState, useEffect } from "react";
import axios from "axios";
import { Table, Popover, notification, Button, Tooltip } from "antd";
import {
  CheckOutlined,
  MinusOutlined,
  FilePdfFilled,
  InfoCircleOutlined,
  QuestionOutlined,
  CloseOutlined,
} from "@ant-design/icons";

import { formatDateTime } from "../../helpers/date";

const downloadReport = (payoutId) => {
  axios
    .post(`/api/payout/${payoutId}/advice-report`, {})
    .then((response) => {
      window.open(response.data.url);
    })
    .catch(async (err) => {
      notification.error({
        message: `Error when downloading Payout Report`,
        description: err.response?.data.message || err.message,
      });
    });
};

const PayoutList = (props) => {
  const [payouts, setPayouts] = useState(props.payouts);
  const [selectedRows, setSelectedRows] = useState(props.selectedRows);

  useEffect(() => {
    setPayouts(props.payouts);
  }, [props.payouts]);

  const table = [
    {
      title: "Payout ID",
      dataIndex: ["objectId"],
    },
    {
      title: "Remark",
      dataIndex: ["remark"],
    },
    {
      title: "Beneficiary Merchant",
      dataIndex: ["user"],
      render: (user, payout) => {
        const bankDetails = (
          <p>
            <strong>Bank</strong>: {payout?.payload?.bankName}
            <br />
            <strong>Bank Account</strong>: {payout?.payload?.accountNumber}
            <br />
            <strong>Account Name</strong>: {payout?.payload?.accountName}
            <br />
          </p>
        );

        return (
          <>
            {`${user.countryCode || ""}${user.phone || ""} (${
              user.email || ""
            }) `}
            <Popover content={bankDetails}>
              <InfoCircleOutlined />
            </Popover>
          </>
        );
      },
    },
    {
      title: "Total (RM)",
      dataIndex: "amount",
      render: (amount, payout) => {
        const totalDetails = (
          <p>
            <strong>COD Total</strong>: {amount}
            <br />
            <strong>Adjustment</strong>: {payout?.adjustmentValue || "-"}
            <br />
          </p>
        );

        return (
          <>
            {(payout.grandTotal || amount).toFixed(2)} ({payout.orderIds.length}{" "}
            Orders){` `}
            <Popover content={totalDetails}>
              <InfoCircleOutlined />
            </Popover>
          </>
        );
      },
    },
    {
      title: "Date",
      dataIndex: "createdAt",
      render: (text) => formatDateTime(text),
    },
    {
      title: "Status",
      dataIndex: "successAt",
      render: (successAt, payout) => {
        if (successAt) {
          return <CheckOutlined style={{ color: "green" }} />;
        }

        if (payout.error) {
          return (
            <Tooltip title={payout.error}>
              <CloseOutlined style={{ color: "red" }} />
            </Tooltip>
          );
        }

        return <MinusOutlined style={{ color: "red" }} />;
      },
    },
    {
      title: "Email",
      dataIndex: "isEmailSent",
      render: (isEmailSent) => {
        if (isEmailSent) {
          return <CheckOutlined style={{ color: "green" }} />;
        }

        return <MinusOutlined style={{ color: "red" }} />;
      },
    },
    {
      title: "Transaction Number",
      dataIndex: "ref_no",
    },
    {
      title: "Action",
      dataIndex: "objectId",
      render: (id, payout) => {
        if (payout.successAt) {
          return (
            <Tooltip title="Payment Advice">
              <Button onClick={() => downloadReport(id)}>
                <FilePdfFilled />
              </Button>
            </Tooltip>
          );
        }

        if (!payout.successAt && !payout.error)
          return (
            <Tooltip title="Check Status">
              <a target="_blank" href={`/payout/status/${id}`}>
                <Button>
                  <QuestionOutlined />
                </Button>
              </a>
            </Tooltip>
          );
      },
    },
  ];

  return (
    <div className="table">
      <Table
        rowSelection={{ selectedRows, onChange: props.onSelectRows }}
        rowKey="objectId"
        className="table-section"
        columns={table}
        dataSource={payouts}
        pagination={false}
        scroll={{ x: true }}
        loading={props.loading}
      />
    </div>
  );
};

export default PayoutList;
