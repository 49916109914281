export const USER_BADGES = {
  Whale: "Whale",
  "Rising Star": "Rising Star",
};

export const USER_STATUS = {
  "New User": "New User",
  Risky: "Risky",
  Active: "Active",
  Inactive: "Inactive",
};

export const USER_PACKAGES = {
  dhl_rm_5_membership: "DHL RM 5 Membership",
  first_5_kg_special: "First 5kg Special",
  fookai_6_50: "Fookai 6 50",
  fookai_special: "Fookai Special",
  gold: "Gold",
  membership: "Membership",
  normal: "Normal",
  platinum: "Platinum",
  super_membership: "Super Membership",
};

export const USER_TAGGING = {
  "Credit User": "Credit User",
  "Topup User": "Topup User",
};
