export const SERVICE_PROVIDERS = {
    dhl: 'dhl',
    flash: 'flash',
    jnt: 'jnt',
    ninjavan: 'ninjavan',
    citylink: 'citylink',
    pickupp: 'pickupp',
    teleport: 'teleport',
    poslaju: 'poslaju',
    sfstandard: 'sfstandard',
    sfeconomy: 'sfeconomy',
    fedex: 'fedex',
    imile: 'imile',
    redly: 'redly',
    best:"best",
    aramex:"aramex",
    lineclear:"lineclear"
}

export const SERVICE_PROVIDER_DETAILS = [
    {
        key: 'dhl',
        name: 'DHL',
    },
    {
        key: 'flash',
        name: 'Flash',
    },
    {
        key: 'jnt',
        name: 'J&T',
    },
    {
        key: 'ninjavan',
        name: 'Ninjavan',
    },
    {
        key: 'citylink',
        name: 'Citylink',
    },
    {
        key: 'pickupp',
        name: 'Pickupp',
        isPublic: false,
    },
    {
        key: 'teleport',
        name: 'Teleport',
    },
    {
        key: 'poslaju',
        name: 'Poslaju',
    },
    {
        key: 'sfstandard',
        name: 'SF Standard',
    },
    {
        key: 'sfeconomy',
        name: 'SF Economy',
    },
    {
        key: 'fedex',
        name: 'Fedex',
        isPublic: false,
    },
    {
        key: 'aramex',
        name: 'Aramex',
        isPublic: false,
    },
    {
        key: 'imile',
        name: 'IMile',
    },
    {
        key: 'redly',
        name: 'Redly',
    }
]

export const COD_SERVICE_PROVIDERS = [
    SERVICE_PROVIDERS.flash,
    SERVICE_PROVIDERS.dhl,
    SERVICE_PROVIDERS.ninjavan,
    SERVICE_PROVIDERS.jnt,
    SERVICE_PROVIDERS.poslaju,
    SERVICE_PROVIDERS.citylink,
    SERVICE_PROVIDERS.imile,
    SERVICE_PROVIDERS.redly,
    SERVICE_PROVIDERS.best,
    SERVICE_PROVIDERS.aramex
]

export const WEBHOOKLESS_SERVICE_PROVIDERS = [
    SERVICE_PROVIDERS.poslaju,
    SERVICE_PROVIDERS.citylink,
    SERVICE_PROVIDERS.lineclear,
    SERVICE_PROVIDERS.aramex,
]